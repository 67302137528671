<template>
    <div class="edit">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Edit Profile</div>
        </div>

        <div class="main">
            <div class="dp">
                <img :src="image"/>

                <div class="click" @click="open_pop()">
                    click to change picture
                </div>
            </div>
            <div class="input">
                <div class="label">Bio</div>
                <textarea placeholder="Enter your bio" v-model="bio"></textarea>
            </div>
            <div class="input">
                <div class="label">Birthday</div>
                <input type="date" v-model="birthday">
                <div class="n">Your birth year won't be public</div>
            </div>
            <div class="input">
                <div class="label">Location</div>
                <input type="text" placeholder="Enter your current location" v-model="location">
            </div>
            <div class="input">
                <div class="label">Religion</div>
                <input type="text" placeholder="What's your religion" v-model="religion">
            </div>
            <div class="input">
                <div class="label">Languages</div>
                <input type="text" placeholder="What languages do you speak" v-model="language">
                <div class="n">Seperate with comma ","</div>
            </div>
            <div class="input">
                <div class="label">Relationship</div>
                <select v-model="relationship">
                    <option value="" selected disabled>What's your relationship status</option>
                    <option value="Single">Single</option>
                    <option value="In Love">In Love</option>
                    <option value="Dating">Dating</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                </select>
            </div>
            <div class="input">
                <div class="label">Phone Number</div>
                <input type="text" placeholder="Enter your phone number" v-model="phone">
            </div>
            <div class="input">
                <div class="label">Whatsapp Number</div>
                <input type="text" placeholder="Enter your whatsapp number" v-model="whatsapp">
            </div>
        </div>
        <div class="foot">
            <div class="note">Click the save button to apply changes</div>

            <loader :height="'35px'" v-if="loading"/>
            <div class="btn" @click="save()" v-else>Save</div>
        </div>

        <div class="popup" v-show="pop">
            <div class="dark" @click="cancel()"></div>
            
            <transition name="slide">
                <div class="box" v-show="show_box">
                    <div class="box_title">Add/Change Profile Picture</div>
                    
                    <croppa v-model="myCroppa" 
                        :width="size"
                        :height="size"
                        :accept="'image/*'"
                        :placeholder="'Add Image'" 
                        :placeholder-font-size="14"
                        :zoom-speed="5"
                        :quality="1"
                        :disable-rotation="true"
                        :prevent-white-space="true"
                        :show-remove-button="true"
                        :canvas-color="'#edeef0'"
                        :remove-button-color="'rgb(142, 72, 153)'"
                        :remove-button-size="25"
                        @image-remove="remove()">
                        
                    </croppa>

                    <div class="down">
                        <div class="note">You can also zoom and drag image</div>
                        <div class="opts">
                            <div class="btn" @click="cancel()">Cancel</div>
                            <div class="btn" @click="done()">Done</div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import back from '../../icons/back.vue';
import loader from '../../components/loading.vue';

export default {
    components: {
        back,
        loader
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.cancel()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    data() {
        return {
            loading: false,
            profile: {},

            myCroppa: null,
            is_image: 0,
            image: '',
            image_file: '',

            bio: '',
            birthday: '',
            location: '',
            language: '',
            religion: '',
            relationship: '',
            phone: '',
            whatsapp: '',

            pop: false,
            show_box: false,
            size: 0
        }
    },
    
    mounted() {
        this.profile = JSON.parse(localStorage.getItem('profile'))

        this.image = this.profile.dp
        this.bio = this.profile.bio
        this.birthday = this.profile.birthday
        this.location = this.profile.location
        this.language = this.profile.language
        this.religion = this.profile.religion
        this.relationship = this.profile.marital
        this.phone = this.profile.phone
        this.whatsapp = this.profile.whatsapp

        this.size = window.innerWidth
    },

    methods: {
        
        back() {
            if (this.loading) { return }
            this.$router.go(-1)
        },
        open_pop() {
            this.pop = true
            setTimeout(() => {
                this.show_box = true
            }, 100);
        },
        cancel() {
            this.show_box = false
            setTimeout(() => {
                this.pop = false
            }, 100);
        },
        done() {
            if (this.myCroppa.hasImage()) {
                this.myCroppa.generateBlob(blob => {
                    let name = 'image.' + blob.type.split('/')[1]
                    this.image_file = new File([blob], name)
                    this.is_image = 1

                    this.image = this.myCroppa.generateDataUrl()
                })
            } else {
                this.image = this.profile.dp
                this.image_file = null
                this.is_image = 0
            }
            this.cancel()
        },
        remove() {
            this.image = this.profile.dp
            this.image_file = null
            this.is_image = 0
        },

        save() {
            this.loading = true

            let form = new FormData()
            form.append('is_image', this.is_image)
            if (this.is_image) {
                form.append('image', this.image_file)
            }
            form.append('bio', this.bio)
            form.append('birthday', this.birthday)
            form.append('location', this.location)
            form.append('language', this.language)
            form.append('religion', this.religion)
            form.append('relationship', this.relationship)
            form.append('phone', this.phone)
            form.append('whatsapp', this.whatsapp)

            this.$http.post('/user/update', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.saved()

                    if (this.is_image) {
                        this.profile.dp = r.dp
                        this.$root.$data.dp = r.dp
                        
                        db.collection('auth').doc({ id: 1 }).update({
                            'dp': r.dp
                        })
                    }

                    this.profile.bio = this.bio
                    this.profile.birthday = this.birthday
                    this.profile.location = this.location
                    this.profile.language = this.language
                    this.profile.religion = this.religion
                    this.profile.marital = this.relationship
                    this.profile.phone = this.phone
                    this.profile.whatsapp = this.whatsapp
                    
                    localStorage.setItem('profile', JSON.stringify(this.profile))
                }
                
                this.loading = false
            })
        }
    },
    notifications: {
        saved: {
            'type': 'success',
            'title': 'Saved',
            'message': 'Your changes has been saved!'
        }
    }
}
</script>

<style scoped>
    .edit {
        height: 100vh;
        width: 100%;
        background-color: white;
    }
    .head {
        display: grid;
        grid-template-columns: calc(50% - 40px) 50%;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .title {
        font-weight: 500;
    }

    .main {
        height: calc(100vh - 144px);
        overflow: auto;
        padding: 15px;
        box-sizing: border-box;
    }

    .dp {
        margin: 0 auto;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .dp img {
        width: 120px;
    }
    .click {
        position: absolute;
        bottom: 0px;
        left: 0px;
        text-align: center;
        padding: 10px;
        background-color: var(--trans);
        color: var(--main);
        font-size: 12px;
        font-weight: 600;
    }
    .input {
        margin-top: 15px;
    }
    .label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    input, textarea, select {
        outline: none;
        padding: 13px;
        border-radius: 5px;
        border: 1px solid #ddd;
        border: none;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
        background-color: #edeef0;
    }
    .n {
        font-size: 12px;
        color: grey;
        text-align: right;
    }




    .foot {
        border-top: 1px solid #ddd;
        padding: 15px;
    }
    .note {
        font-size: 12px;
        font-weight: 500;
        color: gray;
        margin-bottom: 10px;
    }
    .btn {
        font-weight: 500;
        font-size: 14px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        padding: 8px;
        text-align: center;
    }


    .box {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .box_title {
        font-weight: 500;
        padding: 15px;
    }
    .down {
        padding: 15px;
    }
    .opts {
        display: grid;
        grid-template-columns: auto auto;
        gap: 15px;
    }
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -50vh;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>